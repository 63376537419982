<template>
  <div class="hk-content">
    <div class="hk-favorite">
      <h4>{{ $t('favorite.title') }}</h4>
      <ul class="hk-product">
        <li
          v-for="(item, index) of favouriteList"
          :key="index"
          class="hk-product__item"
          @click="goDetail(item.id)"
        >
            <i
              class="el-icon-delete-solid"
              @click.stop="handleDelete(item.id)"
            />
            <h4>{{ item.category_name }}</h4>
            <h3>{{ item.product_name }}</h3>
            <img :src="item.square_img">
            <ul>
              <li
                v-for="(intro, index) in item.introduce"
                :key="index"
              >
                {{ intro }}
              </li>
            </ul>
            <p>
              <b>HK$</b>
              <span>{{ item.origin_price }}</span> / {{ $t('common.carton') }}
            </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HkFavorite',
  data() {
    this.getFavouriteList();
    return {
      favouriteList: [],
    }
  },
  computed: {
    products () {
    }
  },
  methods: {
    handleDelete (id) {
      let self = this;
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        }
      }
      let formData = new FormData();
      formData = {
        product_id: id,
      }
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/product/dislike'
      this.axios.post(apiCall, formData, header)
      .then(function (response) {
        let code = response.data.code;
        switch(code) {
          case 0 :
            self.getFavouriteList();
            break
        }
      })
      .catch(function (error) {
        console.log(error);
      })
    },
    goDetail (id) {
      this.$router.push({
        name: 'detail',
        query: { id }
      })
    },
    getFavouriteList () {
      let self = this;
      let header = {
        headers: {
          token: localStorage.getItem('SSID'),
        }
      }
      let rootApi = self.$store.state.config.apiRoot;
      let apiCall = rootApi + '/api/product/showLikes'
      this.axios.get(apiCall, header)
      .then(function (response) {
        self.favouriteList = response.data.data.list;
      })
      .catch(function (error) {
        console.log(error);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import './styles/favorite';
</style>
